$(document).ready(function() {
	

	
	$('.toplink').on('click', function(e) {
		scrollTo(100);
		e.preventDefault();
	});
	
	var homePage = Barba.BaseView.extend({
		namespace: 'homePage',
		onEnter: function() {
 			window.scrollTo(0, 0);	
		},
		onEnterCompleted: function() {
			_paq.push(['setDocumentTitle', "homePage"]);
			_paq.push(['trackPageView']);
			var homeswiper = new Swiper ('.home__swiper', { 
					
				loop: true,
				slidesPerView: 1
				
			});
			$('.btn_swiper-previous').on('click', function(){
				homeswiper.slidePrev(); 
			});
			$('.btn_swiper-next').on('click', function(){
				homeswiper.slideNext(); 
			});

		}
	});
	
	var projectsOverview = Barba.BaseView.extend({
		namespace: 'projectsOverview',
		onEnter: function() {
			window.scrollTo(0, 0);	
		},
		onEnterCompleted: function() {
			_paq.push(['setDocumentTitle', "projectsOverview"]);
			_paq.push(['trackPageView']);			
		}
	});
	
	var projectDetail = Barba.BaseView.extend({
		namespace: 'projectDetail',
		onEnter: function() {
			window.scrollTo(0, 0);
// 			document.body.scrollTop = 0;
// 			$(window).scrollTop(0)
		},
		onEnterCompleted: function() {
// 			window.scrollTo(0, 0); 
			_paq.push(['setDocumentTitle', "projectDetail"]);
			_paq.push(['trackPageView']);


// 			$(".reveal_this").footerReveal();
			var swiperInstances = {};
			$(".swiper-container-project").each(function(index, element) {
				var $this = $(this);
				var $hud = $this.find('.hud');
				$this.addClass("instance-" + index);
				$this.find(".btn_swiper-previous").addClass("btn_swiper-previous-" + index);
				$this.find(".btn_swiper-next").addClass("btn_swiper-next-" + index);
				swiperInstances[index] = new Swiper(".instance-" + index, { /* 		        paginationClickable: true, */
					autoHeight: true,
					nextButton: '.btn_swiper-next',
					prevButton: '.btn_swiper-previous',
					pagination: '.swiper-pagination',
					paginationType: 'fraction',
					loop: true,
					onSlideChangeStart: function(e) {
						var description = e.slides.eq(e.activeIndex).find('img').attr("alt");
						e.slides.eq(e.activeIndex).parents(".swiper-container").find(".hud-description").text(description);
					},
					nextButton: ".btn_swiper-next-" + index,
					prevButton: ".btn_swiper-previous-" + index
				});
			}); //end swiper
			// The new Container is ready and attached to the DOM.		
		}
	});
	
	var contactPage = Barba.BaseView.extend({
		namespace: 'contactPage',
		onEnter: function() {		

		},
		onEnterCompleted: function() {
			_paq.push(['setDocumentTitle', "contactPage"]);
			_paq.push(['trackPageView']);
		}
	});
	
	var profilePage = Barba.BaseView.extend({
		namespace: 'profilePage',
		onEnter: function() {

// 			document.body.scrollTop = 0;
			// The new Container is ready and attached to the DOM.
			
		},
		onEnterCompleted: function() {
			_paq.push(['setDocumentTitle', "profilePage"]);
			_paq.push(['trackPageView']);
		}
		
		
	});
	// Don't forget to init the views before start!
	homePage.init();
	projectsOverview.init();
	projectDetail.init();
	profilePage.init();
	contactPage.init();
	Barba.Pjax.start();
/*
	Barba.Dispatcher.on('newPageReady', function() {
    	window.scrollTo(0, 0);   
  	});
*/
	
/*
	Barba.Dispatcher.on('newPageReady', function(current, prev, container) {
    history.scrollRestoration = 'manual';
});
Barba.Dispatcher.on('initStateChange', function() {
    ga('send', 'pageview', location.pathname);
});
*/
	Barba.Dispatcher.on('newPageReady', function(current, prev, container) {
    history.scrollRestoration = 'manual';
});
	var FadeTransition = Barba.BaseTransition.extend({
		
		start: function() {
			/**
			 * This function is automatically called as soon the Transition starts
			 * this.newContainerLoading is a Promise for the loading of the new container
			 * (Barba.js also comes with an handy Promise polyfill!)
			 */
			// As soon the loading is finished and the old page is faded out, let's fade the new page
			Promise.all([this.newContainerLoading, this.fadeOut()]).then(this.fadeIn.bind(this));
		},
		fadeOut: function() {
			/**
			 * this.oldContainer is the HTMLElement of the old Container
			 */
			return $(this.oldContainer).animate({
				opacity: 0
			}).promise();


		},
		fadeIn: function() {
			/**
			 * this.newContainer is the HTMLElement of the new Container
			 * At this stage newContainer is on the DOM (inside our #barba-container and with visibility: hidden)
			 * Please note, newContainer is available just after newContainerLoading is resolved!
			 */

			var _this = this;
			var $el = $(this.newContainer);
			$(this.oldContainer).hide();
			$el.css({
				visibility: 'visible',
				opacity: 0
			});
// 			window.scrollTo(0, 0);				
			$el.animate({
				opacity: 1
			}, 700, function() {
				
				/**
				 * Do not forget to call .done() as soon your transition is finished!
				 * .done() will automatically remove from the DOM the old Container
				 */
				_this.done();
			});
		}
	});
	/**
	 * Next step, you have to tell Barba to use the new Transition
	 */
	Barba.Pjax.getTransition = function() {
		/**
		 * Here you can use your own logic!
		 * For example you can use different Transition based on the current page or link...
		 */
		return FadeTransition;
	};
}); // end domready
// objectFitImages();



// Navbar hiding functions!
var didScroll;
var lastScrollTop = 0;
var delta = 3;
var navbarHeight = $('.navbar').outerHeight();
$(window).scroll(function(event) {
	didScroll = true;
});

setInterval(function() {
	if (didScroll) {
		hasScrolled();
		didScroll = false;
	}
}, 100);



function hasScrolled() {
	var st = $(this).scrollTop();
	if (Math.abs(lastScrollTop - st) <= delta) return;
	if (st > lastScrollTop && st > navbarHeight && window.innerWidth > 720 ) {
		$('.navbar').removeClass('visible').addClass('hidden');
		
/*
		if ($(window).scrollTop() + window.innerHeight > $(document).height() -200 ) {
			 $('.navbar').removeClass('hidden').addClass('visible bottomreached');			 
		}
*/
	} else {
		// Scroll up 
		if (st + $(window).height() < $(document).height()) {
			$('.navbar').removeClass('hidden bottomreached').addClass('visible');
		}
	}
	lastScrollTop = st;
};


jQuery( function($) {
	jQuery("body").on("click touchend",'.navtoggle__button', function(event) {
        event.preventDefault(); 
	jQuery("#nav").toggleClass('open');
	});
});



function debounce(func, wait, immediate) { // davidwalsh.name/javascript-debounce-function
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};


/*
var mobilenavcheck = debounce(function() {
	// All the taxing stuff you do
}, 250);

window.addEventListener('resize', myEfficientFn);
*/